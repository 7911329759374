/* Whole app */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Noto+Sans&display=swap');

:root {
  --background: white;
  --dark: rgb(68,84,106);
  --light: rgb(105,118,136);
}

body {
  /* font-family: "Montserrat"; */
  font-family: "Noto Sans";
  margin: 0;
  box-sizing: border-box;
  background-color: var(--background);
}

.home {
  position: relative;
  width: 100%;
  height: 100%;
}

.clickable:hover, button:hover {
  cursor: pointer;
}

.environment {
  position: fixed;
  top: 50%;
  left: 0;
  background-color: darkred;
  color: white;
  text-emphasis: bold;
  padding: 8px;
  font-size: 24px;
}

#scroll-to-top {
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: var(--light);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  border: 2px solid var(--dark);
  box-shadow: 2px 2px 4px black;
  z-index: 20;
  cursor: pointer;
  font-size: 18px;
}

/* Header */
header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--dark);
  color: white;
  text-align: left;
  height: 80px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

/* Footer */
footer {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

footer a {
  color: var(--dark);
  margin-left: 8px;
}

#title-div {
  font-size: 32px;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

#title {
  width: 220px;
}

.logout {
  display: inline-flex;
  height: 100%;
  justify-content: right;
  align-items: center;
  flex-wrap: wrap;
}

#user-name {
  display: inline-flex;
  text-align: right;
  text-decoration: underline;
  margin-right: 8px;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  #user-name {
    display: none;
  }
}

.profile-pic {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

#account-menu {
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--light);
  padding: 20px 8px 8px 8px;
  border-bottom-left-radius: 8px;
  display: none;
}

#account-menu-close {
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
}

.account-menu-option {
  text-align: center;
  border: 1px solid var(--dark);
  border-radius: 8px;
  padding: 4px;
  margin-top: 4px;
  cursor: pointer;
}

.account-menu-option:hover {
  background-color: var(--dark);
}

#user-welcome {
  display: flex;
  align-items: center;
  font-size: 18px;
}

#user-welcome img {
  margin-left: 8px;
}

.logout button {
  display: inline-flex;
  /* height: 28px; */
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  padding: 4px;
  background-color: white;
  color: var(--dark);
  text-emphasis: bold;
  border: none;
  border-radius: 4px;
}

/* Privacy Policy */
.privacy-policy {
  width: 80%;
  margin: 10px auto;
}

.privacy-policy h1,h2 {
  color: var(--dark);
}

.privacy-policy a {
  color: var(--dark)
}

/* Login */
.is-blurred {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  pointer-events: none;
  user-select: none;
}

#login {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  background-color: white;
  border: 2px solid var(--dark);
  min-width: 300px;
  width: 60%;
  text-align: center;
  padding: 16px;
  border-radius: 8px;
  color: var(--dark);
  filter: none !important;
	-webkit-filter: none !important;
  z-index: 10;
}

#login h1 {
  margin: 8px 0;
}

.login-button {
  padding: 4px;
  width: 120px;
  background-color: rgb(30,215,96);
  color: white;
  text-emphasis: bold;
  border: none;
  border-radius: 4px;
  margin: auto;
  font-size: 18px;
  position: relative;
}

.login-button img {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

/* Content */
.content-container {
  width: 356px;
  margin: 10px auto;
}

@media screen and (min-width: 430px) {
  .content-container {
    width: 80%;
    min-width: 356px;
  }
}

#click-stopper {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 8;
  display: none;
}

.tabs {
  display: flex;
  justify-content: space-around;
}

.tab {
  justify-content: center;
  display: inline-flex;
  color: var(--dark);
  font-size: 20px;
  width: 50%;  
  padding: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.tab.selected {
  background-color: var(--dark);
  color: white;
  
}

.content {
  background-color: var(--dark);
  margin: 0;
  padding: 8px;
  color: white;
  width: calc(100%-16px);
  min-height: 80vh;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdowns {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.dropdowns select {
  display: inline-flex;
  width: 140px;
  text-align: center;
  padding: 6px;
  cursor: pointer;
}

#create-playlist-btn {
  padding: 4px;
  width: 200px;
  background-color: rgb(30,215,96);
  color: white;
  text-emphasis: bold;
  border: none;
  border-radius: 4px;
  margin: auto;
  font-size: 18px;
  text-align: center;
  position: relative;
}

#create-playlist-btn img {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

#new-playlist {
  position: relative;
  margin: 10px 0;
  background-color: var(--light);
  border-radius: 8px;
  padding: 8px;
}

#new-playlist-close {
  position: absolute;
  top: 2px;
  right: 4px;
  cursor: pointer;
  font-size: 18px;
}

#new-playlist h3 {
  margin: 0 0 4px 0;
}

#playlist-info {
  align-items: center;
  display: flex;
}

#playlist-info img {
  display: inline;
  margin-right: 4px;
}

#playlist-info a {
  color: white;
  display: inline;
}

.top-artists {
  display: none;
}

.top-tracks {
  display: block;
}

.tile {
  background-color: var(--light);
  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
}

.rank {
  float: left;
  font-size: 80px;
}

.pic {
  float: right;
  width: 160px;
}

.details {
  clear: both;
}

.details-hidden {
  display: none;
}

.name {
  font-size: 36px;
}

.track-artists, .artist-genres {
  font-size: 18px;
}

.bottom {
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
}

.bottom-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 86px;
}

.labeled-item {
  width: 100%;
  text-align: center;
}

label {
  font-size: 14px;
  color: var(--dark);
  text-emphasis: bold;
}

.top-track {
  width: 100%;
  border-radius: 8px;
}

.icon {
  width: 20px !important;
  height: 20px !important;
  font-size: 20px;
  object-fit: contain !important;
}

.plug-in {
  width: calc(100%-16px) !important;
  min-height: 80px !important;
  padding: 8px !important;
  margin: 4px 0 !important;
  background-color: var(--dark) !important;
  position: relative !important;
  .pic {
    width: 80px !important;
    position: absolute !important;
    top: 8px !important;
    left: 8px !important;
  };
  .details {
    position: absolute !important;
    width: calc(100% - 124px) !important;
    top: 8px !important;
    left: 92px !important;
    clear: none !important;
  };
  .details-hidden {
    width: calc(100% - 124px) !important;
    display: block !important;
    visibility: hidden !important;
  };
  .name {
    font-size: 18px !important;
  };
  .track-artists {
    font-size: 14px !important;
  };
  .bottom {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    right: 8px !important;
    display: block !important;
    justify-content: center !important;
    margin: 0 !important;
  }
  .bottom-item {
    display: block !important;
    width: 20px !important;
  }
}